import React from 'react'
import '../css/Header.css'
import { Link } from 'react-router-dom';
import assignBadge from '../images/Account Add.png'
import unassignBadge from '../images/Account Cancel.png'
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const Header = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated()

  return (
    <div className='header'>
      <div className="frame">
        <nav>
          <ul className='navbar'>
            <div className="left-buttons">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/add"><img src={assignBadge} alt="Assign Badge" /></Link>
              </li>
              <li>
                <Link to="/remove"><img src={unassignBadge} alt="Unassign Badge" /></Link>
              </li>
            </div>
            <div className="right-buttons">
              {!isAuthenticated && (<li>
                <button onClick={() => instance.loginPopup()}>Login</button>
              </li>)}
              {isAuthenticated && (<li>
                <button onClick={() => instance.logoutPopup()}>Logout</button>
              </li>)}
            </div>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Header;

