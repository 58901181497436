import React from 'react'
import '../css/Footer.css'
import mailLogo from '../images/mail_outline.svg'

const Footer = () => 
  (
    <div className='myFooter'>
      <div className="frame">
        <img src={mailLogo} />
        <div className="text-wrapper">Submit feedback</div>
      </div>
    </div>
  )


export default Footer