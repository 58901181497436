import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../css/ElementTextField.css'; 

const ElementTextField = ({ id, label, value, onChange, placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);


  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div htmlFor={id} className={`element-text-field ${isFocused || value ? 'focused' : ''}`}>
      <label htmlFor={id} className={isFocused || value ? 'float' : ''}>
        {label}
      </label>
       <input
          type="text"
          id={id}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
    </div>
  );
};

ElementTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default ElementTextField;
