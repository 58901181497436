import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import '../css/ElementTextField.css'; 

const ElementFileInput = ({ id, label, value, onChange, placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);
  const fileInputRef = useRef(null);
  const handleDivClick = () => {
    
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }

  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div onClick={handleDivClick} htmlFor={id} className={`element-text-field ${isFocused || value ? 'focused' : ''}`}>
      <label htmlFor={id} className={isFocused || value ? 'float' : ''}>
        {label}
      </label>
      <input 
            className='fileInput'
            ref={fileInputRef}
            type='File'
            id={id}
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
        />
    </div>
  );
};

ElementFileInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default ElementFileInput;
