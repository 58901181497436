import React from 'react'
import '../css/Login.css'
import { useMsal } from '@azure/msal-react';



const Login = () => {
  const { instance, accounts, inProgress } = useMsal();



  return (
    <div className="container">
      <div className="main-content">
        <div className="form">
          <button onClick={() => instance.loginRedirect()}>Login</button>
          <button onClick={() => instance.logoutRedirect()}>Logout</button>
        </div>
      </div>
    </div>

  )
}



export default Login