import React from "react";
import ElementTextField from "../components/ElementTextField";
import Button from "../components/Button";
import { useState } from "react";
import "../css/addBadge.css";
import ElementFileInput from "../components/ElementFileInput";

const UnnassignBadge = () => {
  const [badgeName, setBadgeName] = useState("");
  const [column, setColumn] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleBadgeName = (event) => {
    setBadgeName(event.target.value);
  };

  const handleColumn = (event) => {
    setColumn(event.target.value);
  };

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    // unassign api call
  };

  const [isChecked, setIsChecked] = useState(false); 

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
  };

  return (
    <div className="container">
      <div className="main-content">
        <div className="form">
          <label className="text-wrapper">
            Enter Badge and Select SpreadSheet
          </label>
          <ElementTextField
            id="badgeName"
            label="Enter Badge Name"
            value={badgeName}
            onChange={handleBadgeName}
            placeholder=""
          />
          <ElementFileInput
            id="fileInput"
            label="Select Spreadsheet"
            value=""
            onChange={handleFile}
            placeholder=""
          />
          <ElementTextField
            id="column"
            label="Worksheet Column"
            value={column}
            onChange={handleColumn}
            placeholder=""
          />
          <Button label="Submit" onClick={handleSubmit}></Button>
        </div>
      </div>
    </div>
  );
};

export default UnnassignBadge;
