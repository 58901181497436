import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './views/Login';
import Home from './views/Home';
import AssignBadge from './views/AssignBadge';
import 'bootstrap/dist/css/bootstrap.min.css';
import UnassignBadge from './views/UnnassignBadge';
import { useIsAuthenticated } from "@azure/msal-react";


function App() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      <div className='page-container'>
        <Header />
        {isAuthenticated && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/add" element={<AssignBadge />} />
            <Route path="/remove" element={<UnassignBadge />} />
          </Routes>
        )}
        {!isAuthenticated && (
          <Login />
        )}
        <Footer />
      </div>
    </>
  );
}



export default App;
