import React from 'react';
import '../css/IconButtonGroup.css'; // Import the CSS file for styling
import badgeAdd from '../images/Account Add.png';
import badgeRemove from '../images/Account Cancel.png';
import iconImage3 from '../images/Icons Dark.png';
import PropTypes from 'prop-types';


function IconButtonGroup({assignBadge, unassignBadge}) {

  return (
    <div className="icon-button-group">
      <div className="icon-button">
        <button className="icon-button-inner" onClick={assignBadge}>
          <img src={badgeAdd} alt="Icon 1" className="icon" />
          <div className="label">Assign Badge</div>
        </button>
      </div>
      <div className="icon-button">
        <button className="icon-button-inner" onClick={unassignBadge}>
            <img src={badgeRemove} alt="Icon 1" className="icon" />
            <div className="label">Unnassign Badge</div>
        </button>
      </div>
      <div className="icon-button">
        <button className="icon-button-inner" >
            <img src={iconImage3} alt="Icon 1" className="icon" />
            <div className="label">Thumbnail Generator</div>
        </button>
      </div>
    </div>
  );
}

IconButtonGroup.propTypes = {
  assignBadge:PropTypes.func,
  unassignBadge:PropTypes.func
};


export default IconButtonGroup;
